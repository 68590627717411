import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import SignalInfoPopover from "../SignalInfo/SignalInfo";
import DataField from "../DataField/DataField";
import { isEmptyObject } from "../../helpers/helpers";
import dayjs from "dayjs";
import "./ReportCard.css";
import { tabs } from "../../constants";

const ReportCard = ({ item, index, showInfo, components }) => {
  const { Attrs, SK } = item;
  const manuscript =
    (Attrs.documentTitle ? Attrs.documentTitle : Attrs.documentName) ||
    Attrs.externalPublisherId;
  const timestamp = dayjs(SK.slice(10)).format("YYYY.MM.DD HH:mm");

  const viewDetails = (index, tabId) => {
    showInfo(index, tabId);
  };

  const viewDetailsButtonStyle = {
    fontSize: "0.63em",
    fontWeight: "600",
    height: "2rem",
  };

  const createTextReferenceItem = ({
    label,
    colorCondition,
    value,
    isUnavailable = false,
    processingMessage = "",
  }) => {
    return {
      label,
      labelColor:
        typeof colorCondition !== "string"
          ? !!colorCondition
            ? "red"
            : null
          : colorCondition,
      value,
      popover: <SignalInfoPopover signal={label} />,
      isUnavailable: isUnavailable,
      processingMessage,
    };
  };

  const referenceAnalysis = [
    createTextReferenceItem({
      label: "DOI analysis",
      colorCondition: Attrs?.refIdentifiers?.invalid?.length,
      value: `${Attrs?.refIdentifiers?.invalid?.length ?? 0}/${
        Attrs?.refIdentifiers?.extracted ?? 0
      } (${Attrs?.refIdentifiers?.parsed ?? 0})`,
    }),
    createTextReferenceItem({
      label: "Retraction Watch",
      colorCondition: Attrs?.retractionWatch?.length,
      value: Attrs?.retractionWatch
        ? `${Attrs?.retractionWatch?.length ?? 0} (${
            Attrs?.refIdentifiers?.parsed ?? 0
          })`
        : "n/a",
    }),
  ];

  const clearSkiesPapermillAlarm = [
    createTextReferenceItem({
      label: "Papermill similarity",
      colorCondition:
        !Attrs?.clearSkiesStatus ||
        Attrs?.clearSkiesStatus === "green" ||
        Attrs?.clearSkiesStatus === "none" ||
        isEmptyObject(Attrs?.clearSkiesStatus)
          ? null
          : Attrs?.clearSkiesStatus,
      value: Attrs?.clearSkiesStatus === null ? "n/a" : null,
      isUnavailable:
        !Attrs?.clearSkiesStatus || isEmptyObject(Attrs.clearSkiesStatus),
    }),
  ];

  const isCactusProcessing =
    Attrs?.cactusTool?.status === true && Attrs?.cactusTool?.message;

  const cactus = [
    // PASSED | WARNING | CRITICAL,
    createTextReferenceItem({
      label: "Authorship Issues",
      colorCondition:
        !Attrs?.cactusTool?.authorship ||
        Attrs?.cactusTool?.authorship === "green" ||
        Attrs?.cactusTool?.authorship === "none" ||
        isEmptyObject(Attrs?.cactusTool?.authorship) ||
        !Attrs?.cactusTool?.authorship.some(
          (item) => item.result === "WARNING" || item.result === "CRITICAL"
        )
          ? null
          : "red",
      value: Attrs?.cactusTool === null ? "n/a" : null,
      isUnavailable:
        (!Attrs?.cactusTool?.authorship ||
          isEmptyObject(Attrs.cactusTool?.authorship)) &&
        !isCactusProcessing,
      processingMessage: isCactusProcessing ? Attrs?.cactusTool?.message : "",
    }),
    createTextReferenceItem({
      label: "Reference Issues",
      colorCondition:
        !Attrs?.cactusTool?.references ||
        Attrs?.cactusTool?.references === "green" ||
        Attrs?.cactusTool?.references === "none" ||
        isEmptyObject(Attrs?.cactusTool?.references) ||
        !Attrs?.cactusTool?.references.some(
          (item) => item.result === "WARNING" || item.result === "CRITICAL"
        )
          ? null
          : "red",
      value: Attrs?.cactusTool === null ? "n/a" : null,
      isUnavailable:
        (!Attrs?.cactusTool?.references ||
          isEmptyObject(Attrs.cactusTool?.references)) &&
        !isCactusProcessing,
      processingMessage: isCactusProcessing ? Attrs?.cactusTool?.message : "",
    }),
  ];

  const text = [
    createTextReferenceItem({
      label: "Tortured phrases",
      colorCondition: Attrs?.signalsScore,
      value: Attrs?.signalsScore !== null ? `${Attrs?.signalsScore}` : "n/a",
    }),
    createTextReferenceItem({
      label: "Unnatural text",
      colorCondition: +(Attrs?.gptDetectorScore ?? 0) > 25,
      value: Attrs?.gptDetectorScore === null ? "n/a" : null,
    }),
  ];

  const pubPeer = [
    createTextReferenceItem({
      label: "PubPeer hits",
      colorCondition:
        Attrs?.feetOfClayRatio?.retracted?.length ||
        Attrs?.feetOfClayRatio?.details?.length,
      value: Attrs?.feetOfClayRatio
        ? `${Attrs?.feetOfClayRatio?.retracted?.length ?? 0}/${
            Attrs?.feetOfClayRatio?.details?.length ?? 0
          }/${Attrs?.refIdentifiers?.valid ?? 0} (${
            Attrs?.refIdentifiers?.parsed ?? 0
          })`
        : "n/a",
    }),
  ];

  const createPapermillItem = (label, suspectType) => {
    const suspectData = Attrs?.suspects?.[suspectType];

    return {
      label,
      labelColor: suspectData?.length ? "red" : null,
      value: Array.isArray(suspectData)
        ? suspectData?.length
          ? "" + suspectData.length
          : "0"
        : "n/a",
      popover: <SignalInfoPopover signal={label} />,
    };
  };

  const papermill = [
    components.watchlistFakeAffiliationNames &&
      createPapermillItem("Fake affiliation names", "affiliations"),
    components.watchlistFakeNamesOrEmailAddresses &&
      createPapermillItem("Fake names or e-mail addresses", "identities"),
    components.watchlistFakeEmailDomains &&
      createPapermillItem("Fake e-mail domains", "emails"),
    components.watchlistDisposableEmailDomains &&
      createPapermillItem("Disposable e-mail domains", "tempmails"),
    components.watchlistBadActors &&
      createPapermillItem("Bad actors", "actors"),
    components.watchlistSuspectArticles &&
      createPapermillItem("Suspect articles", "articles"),
    components.watchlistManuscriptsOfferedForSale &&
      createPapermillItem("Manuscripts offered for sale", "manuscripts"),
    components.watchlistMetadataSuspects &&
      createPapermillItem("Metadata suspects", "metadata"),
    components.watchlistChatGPTOutput &&
      createPapermillItem("GenAI response", "gptOutput"),
  ].filter(Boolean);

  return (
    <MDBCard className="report_card_container mb-4">
      <div className="p-3">
        <MDBTypography variant="p" className="mb-1">
          <small>{timestamp}</small>
        </MDBTypography>
        <MDBTypography variant="h5" className="mb-4">
          {manuscript}
        </MDBTypography>

        <MDBRow className="mb-2">
          <MDBCol md={4}>
            <DataField
              title={"PubPeer"}
              items={pubPeer}
              viewDetails={() => viewDetails(index, tabs.externalTools)}
            />
            <DataField
              title={"ClearSkies Papermill Alarm"}
              items={clearSkiesPapermillAlarm}
              viewDetails={() => viewDetails(index, tabs.externalTools)}
            />
            {components.cactusTool && (
              <DataField
                title={"Cactus pilot"}
                items={cactus}
                viewDetails={() => viewDetails(index, tabs.externalTools)}
              />
            )}
          </MDBCol>
          <MDBCol md={4}>
            <DataField
              title={"Reference analysis"}
              items={referenceAnalysis}
              viewDetails={() => viewDetails(index, tabs.analysisTools)}
            />
            <DataField
              title={"Text analysis"}
              items={text}
              viewDetails={() => viewDetails(index, tabs.analysisTools)}
            />
            {/* <DataField
              title={"Metadata analysis"}
              items={metadataAnalysis}
              viewDetails={() => viewDetails(index, "metadata")}
            /> */}
          </MDBCol>

          <MDBCol md={4}>
            <DataField
              title={"Watchlist"}
              items={papermill}
              viewDetails={() => viewDetails(index, tabs.watchlist)}
            />
          </MDBCol>
        </MDBRow>
        <MDBBtn
          onClick={() => viewDetails(index, tabs.externalTools)}
          outline
          size="sm"
          color="primary"
          className="mt-3 px-3 d-flex align-items-center"
          style={viewDetailsButtonStyle}
        >
          <MDBIcon icon="eye" size="lg" className="me-2" fixed />
          View Details
        </MDBBtn>
      </div>
    </MDBCard>
  );
};

export default ReportCard;
