import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  MDBTypography,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useAuth } from "../../components/Auth";
import {
  itemsPerPage,
  webSocketUrl,
  token as TOKEN,
  tabs,
} from "../../constants";
import {
  deleteEvent,
  getEvents,
  exportEvents,
  genReport,
  uploadFile,
} from "../../helpers/events";
import {
  convertComponentNames,
  showSuccessToast,
  showErrorToast,
  showInfoToast,
  clearToasts,
} from "../../helpers/helpers";
import NoContentMessage from "../../components/NoContentMessage/NoContentMessage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import Modal from "../../components/Modal/Modal";
import FilterSelectorModal from "../../components/FilterSelectorModal/FilterSelectorModal";
import ReportCard from "../../components/ReportCard/ReportCard";
import UploadDropzone from "../../components/UploadDropzone/UploadDropzone";
import Pagination from "rc-pagination";
import "../../assets/rc-pagination.css";
import SearchTextField from "../../components/SearchTextField/SearchTextField";

const useTrait = (initialValue) => {
  const [trait, updateTrait] = useState(initialValue);
  let current = trait;
  const get = () => current;
  const set = (newValue) => {
    current = newValue;
    updateTrait(newValue);
    return current;
  };
  return {
    get,
    set,
  };
};

const DropdownWrapper = styled.div`
  .fa-stack .fa {
    color: grey;
  }
  .fa-stack .fa.fa-circle {
    opacity: 0;
    color: lightgray;
  }

  .fa-stack:hover .fa.fa-ellipsis-v {
    color: black;
  }
  .fa-stack:hover .fa.fa-circle {
    opacity: 1;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-item {
    padding: 12px !important;
    font-size: 14px !important;
    color: black;
  }

  .dropdown-menu > li:last-child .dropdown-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const CustomDropdown = ({ children }) => {
  return (
    <DropdownWrapper>
      <MDBDropdown>
        <MDBDropdownToggle
          size="lg"
          className="btn-floating"
          color="tertiary"
          rippleColor="light"
        >
          <span className="fa-stack fa-lg">
            <i className="fa fa-circle fa-stack-2x"></i>
            <i className="fa fa-ellipsis-v fa-stack-1x"></i>
          </span>
        </MDBDropdownToggle>
        <MDBDropdownMenu responsive="end" color="primary" className="py-2">
          {children}
        </MDBDropdownMenu>
      </MDBDropdown>
    </DropdownWrapper>
  );
};

const PapermillCheck = () => {
  const [updated, forceUpdate] = useReducer((x) => x + 1, 0);
  const [datalistIndex, setDatalistIndex] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(1);
  const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tabId, setTabId] = useState(tabs.externalTools);
  const [eventDeleting, setEventDeleting] = useState(false);

  const [search, setSearch] = useState("");
  const { componentFlags } = useAuth();
  const toastId = React.useRef(null);

  const ws = useRef(null);
  const guardPeriod = useTrait(false);

  const showInfo = (index, tab) => {
    setTabId(tab);
    setDatalistIndex(index);
    setIsArticleModalOpen(true);
  };

  const onFileSubmit = async (files) => {
    toastId.current = showInfoToast("Submitting", false);
    try {
      for (const file of files) {
        toast.update(toastId.current, {
          render: `Submitting ${file.name} ...`,
        });
        await uploadFile(file);
      }
      toast.dismiss(toastId.current);
      showSuccessToast(
        <>
          Submitted successfully!
          <br />
          Please note processing can take several seconds. The page will refresh
          automatically when ready.
        </>,
        250000
      );
    } catch (error) {
      toast.dismiss(toastId.current);
      showErrorToast(error);
    }
  };

  const onExportReports = () => {
    exportEvents()
      .then(({ data: url }) => {
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(console.error);
  };

  const onLoadReport = () => {
    genReport(modalContent, convertComponentNames(componentFlags))
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const dct =
          modalContent.documentName || modalContent.externalPublisherId;
        a.download = dct.match(/([\w\d_-]*)\.?[^\\\/]*$/i)[1] + ".report.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(console.error);
  };

  const onDeleteEvent = async () => {
    setEventDeleting(true);
    const id = data[datalistIndex].id;

    try {
      const response = await deleteEvent(id);

      if (response.data.success) {
        showSuccessToast("Removed successfully");
        forceUpdate();
      } else {
        showErrorToast("Removal failed");
      }
    } catch (error) {
      console.error(error);
      showErrorToast("Error occurred while removing");
    } finally {
      setEventDeleting(false);
      setIsArticleModalOpen(false);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * itemsPerPage + 1);
  };

  useEffect(() => {
    setLoading(true);
    getEvents(offset, itemsPerPage, search, filter)
      .then((res) => {
        setData(res.data.events);
        setTotalItems(res.data.total);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
  }, [offset, updated, search, filter]);

  const handleWebSocketOpen = () => {
    ws.current.heartbeatTimer = setInterval(webSocketHeartbeat, 300000);
    console.log("<websocket opened>");
  };

  const handleWebSocketClose = () => {
    console.log("<websocket closed>");
    clearInterval(ws.current?.heartbeatTimer);
  };

  const handleWebSocketMessage = (messageEvent) => {
    console.log("<websocket message>");
    const data = JSON.parse(messageEvent.data);
    const eventType = data.event?.type;

    switch (eventType) {
      case 'big-batch':
        handleBigBatchEvent(data.event);
        break;
      case 'new':
        handleNewEvent();
        break;
      case 'update':
        forceUpdate();
        break;
      default:
    }
  };

  const handleBigBatchEvent = ({ count, documentName }) => {
    showInfoToast(
      <>
        {`We have detected ${count} references in the uploaded document ${documentName}`}
        <br />
        Processing a large number of references can take longer time. We will notify you by e-mail when the processing is complete.
      </>,
      10000
    );
  };

  const handleNewEvent = () => {
    if (!guardPeriod.current) {
      guardPeriod.current = true;
      setTimeout(() => {
        guardPeriod.current = false;
        clearToasts();
        forceUpdate();
      }, 5000);
    }
  };

  const webSocketHeartbeat = () => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ type: "heartbeat" }));
      console.log("<websocket heartbeat>");
    }
  };

  const initializeWebSocket = () => {
    const token = localStorage.getItem(TOKEN);
    if (!token) return;

    ws.current = new WebSocket(`${webSocketUrl}?authToken=${token}`);

    ws.current.onopen = handleWebSocketOpen;
    ws.current.onclose = handleWebSocketClose;
    ws.current.onmessage = handleWebSocketMessage;
  };

  useEffect(() => {
    initializeWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const modalContent = data[datalistIndex]?.Attrs
    ? data[datalistIndex].Attrs
    : "";

  return (
    <div className="main-section">
      <div className="container">
        <>
          <Modal
            isOpen={isArticleModalOpen}
            setIsOpen={setIsArticleModalOpen}
            tab={tabId}
            data={modalContent}
            onLoadReport={onLoadReport}
            onDeleteEvent={onDeleteEvent}
            eventDeleting={eventDeleting}
            components={convertComponentNames(componentFlags)}
          />

          <FilterSelectorModal
            isOpen={isFilterModalOpen}
            setIsOpen={setIsFilterModalOpen}
            filter={filter}
            setFilter={setFilter}
            components={convertComponentNames(componentFlags)}
          />

          <UploadDropzone onFileSubmit={onFileSubmit} />
          <hr style={{ color: "#bab4b4" }} />
          <div className="d-flex justify-content-end">
            <SearchTextField setSearch={setSearch} search={search} />
          </div>
          <div>
            <SpinnerOverlay active={loading} centerOnScreen={true}>
              {error ? (
                <ErrorMessage />
              ) : (
                <>
                  {data ? (
                    <>
                      <div className="d-flex align-items-center mb-3">
                        <MDBTypography
                          variant="h4"
                          className="mb-0 fw-bolder text-primary"
                        >
                          Reports
                        </MDBTypography>
                        <MDBBtn
                          outline
                          floating
                          size="sm"
                          className="ms-2"
                          onClick={() => setIsFilterModalOpen(true)}
                        >
                          <i
                            className="fas fa-filter"
                            style={{ fontSize: "0.85em" }}
                          />
                        </MDBBtn>
                        <div className="d-flex align-items-center ms-auto">
                          <Pagination
                            simple
                            total={totalItems}
                            pageSize={itemsPerPage}
                            current={currentPage}
                            onChange={handlePageClick}
                            locale={"en_US"}
                          />
                          <CustomDropdown>
                            <MDBDropdownItem link onClick={onExportReports}>
                              <MDBIcon className="px-3 fas fa-table fa-1x" />
                              Export
                            </MDBDropdownItem>
                          </CustomDropdown>
                        </div>
                      </div>
                      {data.length > 0 &&
                        data.map((item, index) => (
                          <ReportCard
                            key={item.SK}
                            item={item}
                            index={index}
                            showInfo={showInfo}
                            components={convertComponentNames(componentFlags)}
                          />
                        ))}
                      {!(data?.length > 0) && (
                        <NoContentMessage message={"No data"} />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </SpinnerOverlay>
          </div>
        </>
      </div>
    </div>
  );
};

export default PapermillCheck;
